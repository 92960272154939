<template>
	<div>
		<f-default-header
			:title="$t('pages.contact.title')"
			:subtitle="$t('pages.contact.new.subtitle')" />

		<f-form />

		<f-actions-btn-holder>
			<v-btn color="secondary" elevation="0" large @click="back">
				{{ $t("labels.btnBack") }}
			</v-btn>
			<v-btn color="primary" elevation="0" large @click="send">
				{{ $t("labels.btnSend") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import FForm from "../../components/contact/Form.vue";

export default {
	components: {
		FForm,
	},
	methods: {
		back() {
			this.$router.push({
				name: "contact-list",
			});
		},
		async send() {
			await this.$store.dispatch("contact/send");
			this.back();
			// this.$router.push({
			//     name: 'contact-create'
			// })
		},
	},
};
</script>
