<template>
	<f-card>
		<v-row>
			<v-col cols="12">
				<f-card-title>Mensagem</f-card-title>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-textarea v-model="ticket.message" outlined dense hide-details />
			</v-col>
		</v-row>
	</f-card>
</template>

<script>
export default {
	data: () => ({
		permissions: {
			read: false,
			write: false,
		},
	}),
	created() {
		this.permissions = this.$getPermissions();
	},
	computed: {
		ticket() {
			return this.$store.state.contact.view;
		},
	},
};
</script>
